import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';

const CottonPage: FC<any> = ({ data }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  let hasImages = null;

  function checkImages () {
    if (data.alaskaospPhotos.edges[selectedIndex]) {
      hasImages = true;
      return <div className='itemImages'>
        <div className='photo'>
          <div className='large' key={data.alaskaospPhotos.edges[selectedIndex].node.id}>
            <Img
              fluid={data.alaskaospPhotos.edges[selectedIndex].node.childImageSharp.fluid}
              alt={data.alaskaospPhotos.edges[selectedIndex].node.base}
            />
          </div>
        </div>
        <div className='thumbnails'>
          {data.alaskaospPhotos.edges.map((image, index) => (
            <div className={index === selectedIndex ? 'thumb selected' : 'thumb'} key={image.node.id} onClick={(() => setSelectedIndex(index))}>
              <Img
                fixed={image.node.childImageSharp.fixed}
                alt={image.node.base}
              />
            </div>
          ))}
        </div>
      </div>;
    }
  }

  return (
    <Layout>
      <SEO title="Alaskaösp" />
      {hasImages ? <Helmet bodyAttributes={{ class: 'info-page' }} /> : null}
      <h3>Alaskaösp (<i>Populus trichocarpa</i> Torr. &amp; Gray) </h3>
      <p>
        Vex hratt og bindur mikið. Verður ásamt sitkagreni stórvöxnust trjátegunda
        á Íslandi. Æskuvöxtur er hraður og afföll lítil ef réttur klónn og
        undirbúningur lands er valinn.
      </p>
      <p>
        Getur vaxið við hafrænt loftslag og er harðgerð. Bestu svæðin á Íslandi
        eru Fljótsdalshérað innanvert og Fljótshlíð á Suðurlandi..
      </p>
      <p>
        Þarf mjög frjósamt land og bestur er vöxturinn á frjósömu landi, gjarnan
        framræstu. Miðlungsvöxt er hægt að fá á valllendi en á rýrara landi verður
        vöxtur lítill og öspin koðnar fljótt niður. Undantekning eru ógrónar
        áreyrar þar sem ösp getur náð sæmilegum vexti, þá með áburðargjöf eða í
        lúpínustóði. Dæmi um þetta eru aspir á Markarfljótsaurum.
      </p>
      <p>
        Alaskaösp getur fjölgað sér með stubbaskotum og rótarskotum í næsta nágrenni
        við móðurtrén. Fræplöntur af alaskaösp eru sjaldgæfar en í seinni tíð hefur
        þeim fjölgað töluvert, t.d. á höfuðborgarsvæðinu. Fræ er létt og fiðrað og
        berst um langan veg.
      </p>
      <p>
        Nýtingarhlutfall borðviðar og iðnviðar er hátt.
      </p>
      {checkImages()}
    </Layout>
  );
};

export default CottonPage;

export const pageQuery = graphql `
  query {
    alaskaospPhotos: allFile(filter: {relativeDirectory: {eq: "alaskaosp"}}) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid(maxWidth: 595, maxHeight:460, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          childImageSharp {
            fixed(height: 135, width: 135, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
